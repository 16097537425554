import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./loyalty-manager.module.scss"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"
import SquareBlockList from "../../components/SquareBlockList/SquareBlockList"

function createMarkup(safetyString: string) {
  return {__html: safetyString};
}

const challengeItems = [
  "projects.loyalty-manager.challenge.paragraph-1-list.item-1",
  "projects.loyalty-manager.challenge.paragraph-1-list.item-2",
  "projects.loyalty-manager.challenge.paragraph-1-list.item-3",
  "projects.loyalty-manager.challenge.paragraph-1-list.item-4",
]

const LoyaltyManager: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    componentStyles.container,
    "container",
    "grid-2"
  )
  const challengeClasses = classNames(
    componentStyles.challenge,
    "container"
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title",
    "section-title-h2"
  )
  const challengeListClasses = classNames(
    componentStyles.challengeList,
    "grid-3"
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    componentStyles.container,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    componentStyles.largeSectionTitle,
    "large-section-title",
    "section-title-h2"
  )
  const  squareBlockListClasses = classNames(
    componentStyles.container,
    componentStyles.squareBlockList,
    "container"
  )

  const technologyBackgroundClasses = classNames(
    componentStyles.container,
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    componentStyles.largeSectionTitle,
    "large-section-title",
    "section-title-h2"
  )
  const technologyBackgroundTextListClasses = classNames(
    componentStyles.technologyBackgroundTextList,
    "grid-2"
  )

  const resultsClasses = classNames(
    componentStyles.container,
    componentStyles.results,
     "container"
  )
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    componentStyles.largeSectionTitle,
    "large-section-title",
    "section-title-h2"
  )

  const tickItems = [
    intl.formatMessage({
      id: "projects.loyalty-manager.how-it-works.churn-minimization.first-text",
    }),
    intl.formatMessage({
      id: "projects.loyalty-manager.how-it-works.churn-minimization.second-text",
    })
  ]

  const tickItemsBlue = [
    {
      title: intl.formatMessage({
              id: "projects.loyalty-manager.how-it-works.ad-campaigns-optimization.first-text",
            })
    },
    {
      title: intl.formatMessage({
              id: "projects.loyalty-manager.how-it-works.ad-campaigns-optimization.second-text.title",
            }),
      subitems: [
        intl.formatMessage({
          id: "projects.loyalty-manager.how-it-works.ad-campaigns-optimization.second-text.subitem1",
        }),
        intl.formatMessage({
          id: "projects.loyalty-manager.how-it-works.ad-campaigns-optimization.second-text.subitem2",
        })
      ]
    },
  ]
  const resultsTextBenefits = [
    {
      percent: intl.formatMessage({
                id: "projects.loyalty-manager.benefits.list.first-item.digit",
              }),
      text: intl.formatMessage({
              id: "projects.loyalty-manager.benefits.list.first-item.text",
            })
    },
    {
      percent: intl.formatMessage({
                id: "projects.loyalty-manager.benefits.list.second-item.digit",
              }),
      text: intl.formatMessage({
              id: "projects.loyalty-manager.benefits.list.second-item.text",
            })
    },
    {
      percent: intl.formatMessage({
                id: "projects.loyalty-manager.benefits.list.third-item.digit",
              }),
      text: intl.formatMessage({
              id: "projects.loyalty-manager.benefits.list.third-item.text",
            })
    }
  ]

  const squareBlockList = [
    intl.formatMessage({
      id: "projects.loyalty-manager.solution-description.list.first-text",
    }),
    intl.formatMessage({
      id: "projects.loyalty-manager.solution-description.list.second-text",
    }),
    intl.formatMessage({
      id: "projects.loyalty-manager.solution-description.list.third-text",
    }),
    intl.formatMessage({
      id: "projects.loyalty-manager.solution-description.list.forth-text",
    })
  ]

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.loyalty-manager.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.loyalty-manager.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                  id: "projects.loyalty-manager.banner.title",
                })}
            </h1>
          </div>
        </div>
      </section>
      <section className="container">
        <div className={componentStyles.clientCompany}>
          <h2 className="large-section-title section-title-h2">
            {intl.formatMessage({
              id: "projects.loyalty-manager.banner.client-company",
            })}
          </h2>
          <div className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.loyalty-manager.banner.client-company-description",
            })}
          </div>
        </div>
      </section>
      <section className={challengeClasses}>
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={challengeTitleClasses}>
            {intl.formatMessage({
              id: "projects.loyalty-manager.challenge.title",
            })}
          </h2>
          <p className={componentStyles.challengeText}>
            {intl.formatMessage({
              id: "projects.loyalty-manager.challenge.paragraph-1",
            })}
          </p>
        </div>
        <ul className={challengeListClasses}>
          {challengeItems.map((text) => (
            <li
              key={text}
              className={componentStyles.technologyBackgroundTilesItem}
            >
              {intl.formatMessage({
                id: text,
              })}
            </li>
          ))}
        </ul>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
                id: "projects.loyalty-manager.solution-description.title",
              })}
          </h2>
          <div className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
                id: "projects.loyalty-manager.solution-description.paragraph-1",
              })}
          </div>
          <div className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
                id: "projects.loyalty-manager.solution-description.paragraph-2",
              })}
          </div>
        </div>
      </section>
      <section className={squareBlockListClasses}>
        <SquareBlockList itemList={squareBlockList}/>
      </section>
      <section className={technologyBackgroundClasses}>
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
              id: "projects.loyalty-manager.how-it-works.title",
            })}
        </h2>
        <ul className={technologyBackgroundTextListClasses}>
          <li className={componentStyles.technologyBackgroundTextItem+ ' ' + componentStyles.orange}>
            <span className={componentStyles.listTitle}>
              {intl.formatMessage({
                id: "projects.loyalty-manager.how-it-works.churn-minimization.title",
              })}
            </span>
            <ul className={componentStyles.technologyBackgroundCubeList}>
              {tickItems.map(translatePath => (
                <li
                  key={translatePath}
                  className={componentStyles.tickListItem}
                >
                  <div dangerouslySetInnerHTML={createMarkup(intl.formatMessage({id: translatePath,}))}></div>
                </li>
              ))}
            </ul>
          </li>
          <li className={componentStyles.technologyBackgroundTextItem}>
            {intl.formatMessage({
                id: "projects.loyalty-manager.how-it-works.ad-campaigns-optimization.title",
              })}
            <ul className={componentStyles.technologyBackgroundCubeList}>
              {tickItemsBlue.map(object => (
                <li
                  key={object.title}
                  className={componentStyles.tickListItem + ' ' + componentStyles.blueTick}
                >
                  {intl.formatMessage({
                    id: object.title,
                  })}

                  {/* output subitems if they are */}
                  {!!object.subitems?.length && (
                    <ul className={componentStyles.technologyBackgroundSubitems}>
                      {object.subitems.map(subitems => (
                        <li
                          key={subitems}
                          className={componentStyles.tickListItem+ ' ' + componentStyles.blueCube}
                        >
                          {intl.formatMessage({
                            id: subitems,
                          })}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
            {intl.formatMessage({
                id: "projects.loyalty-manager.results.title",
              })}
        </h2>
        <p className={componentStyles.resultsText}>
            {intl.formatMessage({
                id: "projects.loyalty-manager.results.text",
              })}
        </p>
        <p className={componentStyles.resultsTextBlue}>
          {intl.formatMessage({
              id: "projects.loyalty-manager.benefits.title",
            })}
        </p>
        <div className={componentStyles.wrapperBenefitsBlock}>
          <div className={componentStyles.leftBlock}>
            {resultsTextBenefits.map( benefit => (
              <div className={componentStyles.benefitBlock}>
                <div className={componentStyles.percent}>
                  {benefit.percent}
                </div>
                <div className={componentStyles.benefitText}>
                  {benefit.text}
                </div>
              </div>
            ))}
          </div>
          <div className={componentStyles.valuebleBlock}>
            <img
              src="/icons/diamond.svg"
              alt="Diamond image"
              width="45"
              height="48"
              className={componentStyles.dimondIcon}
            />
            <div>
              {intl.formatMessage({
                id: "projects.loyalty-manager.valuable.text",
              })}
            </div>
          </div>
        </div>
      </section>
      <LearnMoreSection/>
    </Layout>
  )
}
export default LoyaltyManager
